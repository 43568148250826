<script>
import { useRoute } from 'vue-router'
import { regFenToYuan } from '@/utils'
import BigNumber from 'bignumber.js'
export default {
  setup() {
    return {
      regFenToYuan,
      BigNumber
    }
  }
    
}
</script>
<template>
  <div class="page">
    <div class="box">
      <p style="width:2rem; height:2rem; margin:.5rem auto"><img src="@/assets/images/icon-success.png" /></p>
      <p class="desc">支付成功</p>
      <p class="desc" style="padding-bottom:.5rem">¥{{BigNumber($route.query.totalFee).div(100)}}</p>
    </div>
    <div style="margin-top:.5rem; padding:0 .4rem">
      <van-button block type="primary" @click="$router.push('/')">完成</van-button>
    </div>
  </div>
</template>
<style scoped>

  .page {  text-align:center }
  .box { overflow: hidden; margin-bottom: .2rem; background:#fff }
  .desc { line-height:1.7; text-align: center}
   .result { padding: 0 .4rem .4rem; }
  .result p { margin-top: .45rem; display: table; width: 100%; font-size: .3rem}
  .result p span { display:table-cell; text-align:justify }
  .result p span:last-child { text-align:right }
</style>